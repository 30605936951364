import React from "react"

import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Container className="mt-5">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage;

export const Head = () => (
  <Seo title="404: Not found" />
)
